export function useCache(page?: Page | null) {
  const { tenantId, jobShopId } = useHeaderStore()
  const { routeCacheTtl } = useRuntimeConfig()

  const tags = [
        `tenantId:${tenantId}`,
        `jobShopId:${jobShopId}`,
  ]

  if (page)
    tags.push(`pageId:${page.id}`)

  useRouteCache((helper) => {
    helper.setCacheable().setMaxAge(routeCacheTtl).addTags(tags)
  })
}
