<template>
  <div v-for="module in contentModules" v-if="contentModules.length">
    <IntersectWrapper
      v-if="module.component === ContentModules.COVER"
      @mounted="trackModuleExists(contentModules, module)"
      @intersects="trackModuleViewed(contentModules, module)"
    >
      <CRCover
        v-append-parameters="urlParams"
        :module="module"
        @emitted-click="trackModuleClick(contentModules, module)"
      />
    </IntersectWrapper>

    <IntersectWrapper
      v-else-if="module.component === ContentModules.CATEGORIES"
      @mounted="trackModuleExists(contentModules, module)"
      @intersects="trackModuleViewed(contentModules, module)"
    >
      <CRCategories
        v-append-parameters="urlParams"
        :module="module"
        @emitted-click="
          (e) => {
            trackModuleClick(contentModules, module, e.child);
          }
        "
      />
    </IntersectWrapper>

    <IntersectWrapper
      v-else-if="module.component === ContentModules.CARDS"
      @mounted="trackModuleExists(contentModules, module)"
      @intersects="trackModuleViewed(contentModules, module)"
    >
      <CRCards
        v-append-parameters="urlParams"
        :module="module"
        @emitted-click="
          (e) => {
            trackModuleClick(contentModules, module, e.child);
          }
        "
      />
    </IntersectWrapper>

    <IntersectWrapper
      v-else-if="module.component === ContentModules.BENEFITS"
      @mounted="trackModuleExists(contentModules, module)"
      @intersects="trackModuleViewed(contentModules, module)"
    >
      <CRBenefits
        v-append-parameters="urlParams"
        :module="module"
        @emitted-click="trackModuleClick(contentModules, module)"
      />
    </IntersectWrapper>

    <IntersectWrapper
      v-else-if="module.component === ContentModules.IMAGETEXT"
      @mounted="trackModuleExists(contentModules, module)"
      @intersects="trackModuleViewed(contentModules, module)"
    >
      <CRImageText
        v-append-parameters="urlParams"
        :module="module"
        @emitted-click="trackModuleClick(contentModules, module)"
      />
    </IntersectWrapper>
    <IntersectWrapper
      v-else-if="module.component === ContentModules.TEXT"
      @mounted="trackModuleExists(contentModules, module)"
      @intersects="trackModuleViewed(contentModules, module)"
    >
      <CRText
        v-append-parameters="urlParams"
        :module="module"
        @emitted-click="trackModuleClick(contentModules, module)"
      />
    </IntersectWrapper>

    <IntersectWrapper
      v-else-if="module.component === ContentModules.CHAPTER"
      @mounted="trackModuleExists(contentModules, module)"
      @intersects="trackModuleViewed(contentModules, module)"
    >
      <CRChapter
        v-append-parameters="urlParams"
        :module="module"
        @emitted-click="trackModuleClick(contentModules, module)"
      />
    </IntersectWrapper>

    <IntersectWrapper
      v-else-if="module.component === ContentModules.VIDEO"
      @mounted="trackModuleExists(contentModules, module)"
      @intersects="trackModuleViewed(contentModules, module)"
    >
      <CRVideo
        v-append-parameters="urlParams"
        :module="module"
        @emitted-click="trackModuleClick(contentModules, module)"
      />
    </IntersectWrapper>

    <IntersectWrapper
      v-if="module.component === ContentModules.GALLERY"
      @mounted="trackModuleExists(contentModules, module)"
      @intersects="trackModuleViewed(contentModules, module)"
    >
      <CRGallery
        v-append-parameters="urlParams"
        :module="module"
        @emitted-click="
          (e) => {
            trackModuleClick(contentModules, module, e.child);
          }
        "
      />
    </IntersectWrapper>

    <IntersectWrapper
      v-else-if="module.component === ContentModules.FAQ"
      @mounted="trackModuleExists(contentModules, module)"
      @intersects="trackModuleViewed(contentModules, module)"
    >
      <CRFaqs
        v-append-parameters="urlParams"
        :module="module"
      />
    </IntersectWrapper>

    <IntersectWrapper
      v-else-if="module.component === ContentModules.HIRINGPROCESS"
      @mounted="trackModuleExists(contentModules, module)"
      @intersects="trackModuleViewed(contentModules, module)"
    >
      <CRHiringProcess
        v-append-parameters="urlParams"
        :module="module"
      />
    </IntersectWrapper>
  </div>
</template>

<script setup lang="ts">
import { ContentModules } from "@/utils/enums/ContentModules"

const props = defineProps<{
  contentModules: ContentModule[]
}>()

const urlParams = useQueryHeaders(useRequestHeaders())
</script>
