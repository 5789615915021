import { nanoid } from "nanoid"
import Logger from "@/utils/Logger"

export async function useContentModules(pageId: string) {
  const { $api } = useNuxtApp()
  const { t } = useI18n()
  const { tenantId, jobShopId, acceptLanguage } = useHeaderStore()

  const requestId = nanoid()
  const contextString = `content modules for pageId: "${pageId}", tenantId: "${tenantId}" and jobShopId: "${jobShopId}"`

  let startTime
  if (process.server) {
    Logger(requestId).start(`Start fetching ${contextString}`)
    startTime = Date.now()
  }

  const {
    data: contentModules,
    pending: contentModulesPending,
    error: contentModulesError,
  } = await useAsyncData(`contentModules-${pageId}`, () =>
    $api.content.getContentModules(
      tenantId,
      jobShopId,
      pageId,
      acceptLanguage,
    ))

  if (process.server) {
    if (!contentModulesError.value) {
      const duration = Date.now() - startTime
      Logger(requestId).success(`Successfully fetched ${contextString}. (${duration}ms)`)
    }
    else {
      const errorMessage = contentModulesError.value?.data?.errors[0]?.message || contentModulesError.value?.data?.description || "Unknown error occurred"
      Logger(requestId).error(`Error while fetching ${contextString}: ${errorMessage}\n\n`, contentModulesError.value)
    }
  }

  if (contentModulesError.value) {
    throw createError({
      statusCode: contentModulesError.value.statusCode,
      statusMessage: t("error-messages.content-modules-error"),
    })
  }

  return { contentModules, contentModulesPending, contentModulesError }
}
